<template>
  <v-row>
    <v-col cols="12">
      <v-skeleton-loader class="rounded-md" v-if="$store.state.accounts.retrieveLoader" transition="fade-transition" type="article"/>
      <template v-else>
        <v-card class="mb-5" flat>
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-bold">Información de la organización</v-toolbar-title>
          </v-toolbar>
          <v-divider />
          <v-card-text class="pa-0">
            <PermissionDenied v-if="!$helpers.hasPermission(['view_account'])" :isDetail="true" />
            <template v-else>
              <v-col cols="10" md="8" class="pa-5">
                <v-list class="v-list-form-extended-medium transparent pa-0">
                  <v-list-item class="mb-2 pt-0">
                    <v-list-item-title>Nombre</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-col cols="9">
                        <v-text-field v-model="$v.editable.name.$model" :error="$v.editable.name.$error" outlined required single-line class="pa-0" dense placeholder="Acme LLC" maxlength="64" hide-details />
                      </v-col>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="my-2">
                    <v-list-item-title>País</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-col cols="4">
                        <v-select v-model="editable.country" :items="countriesList" item-text="name" item-value="id" single-line disabled hide-details dense height="30" outlined />
                      </v-col>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="my-2">
                    <v-list-item-title>{{$t('generals.Comuna')}}</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-col cols="4">
                        <v-autocomplete v-model="$v.editable.state.$model" :error="$v.editable.state.$error" :items="$store.getters['base/currentStates']" item-text="name" item-value="id" hide-details single-line dense height="30" outlined hide-no-data />
                      </v-col>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="mt-2">
                    <v-list-item-title>Dirección</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-col cols="9">
                        <v-text-field v-model="$v.editable.address.$model" :error="$v.editable.address.$error" outlined required single-line dense maxlength="128" hide-details />
                      </v-col>
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-divider />
              <v-toolbar-title class="px-5 pt-5 subtitle-2 fontBody--text font-weight-bold">Información fiscal</v-toolbar-title>
              <v-col cols="10" md="8" class="pa-5">
                <v-list class="v-list-form-extended-medium transparent pa-0">
                  <v-list-item class="pa-0">
                    <v-list-item-title>{{$t('generals.Número fiscal')}}</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-col cols="4">
                        <v-text-field v-model="editable.tax_id" outlined required single-line dense disabled hide-details />
                      </v-col>
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-divider />
              <v-toolbar-title class="px-5 pt-5 subtitle-2 fontBody--text font-weight-bold">Información de contacto</v-toolbar-title>
              <v-col cols="10" md="8" class="pa-5">
                <v-list class="v-list-form-extended-medium transparent pa-0">
                  <v-list-item class="pa-0">
                    <v-list-item-title>Email</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-col cols="8">
                        <v-text-field v-model="$v.editable.email.$model" :error="$v.editable.email.$error" outlined required single-line dense maxlength="64" hide-details />
                      </v-col>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="my-2">
                    <v-list-item-title>Teléfono</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-col cols="8">
                        <v-text-field v-model="$v.editable.phone.$model" :error="$v.editable.phone.$error" outlined required single-line dense v-mask="'## ### ####'" maxlength="16" hide-details />
                      </v-col>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="my-2">
                    <v-list-item-title>Sitio web</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-col cols="8">
                        <v-text-field v-model="$v.editable.website.$model" :error="$v.editable.website.$error" outlined required single-line dense maxlength="64" hide-details />
                      </v-col>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>Correo de emisión</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-tooltip color="bgSearch" right transition="scale-transition">
                        <template v-slot:activator="{on}">
                          <v-btn class="font-weight-regular" @click="toClipboard('dte@facturachile.cl')" text color="secondary" v-on="on" :ripple="false">dte@facturachile.cl</v-btn>
                        </template>
                        <span class="d-block px-3 py-2">Copiar</span>
                      </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-col>
            </template>
          </v-card-text>
          <template v-if="$helpers.hasPermission(['view_account'])">
            <v-divider />
            <v-card-actions class="px-5 py-4">
              <v-spacer />
              <v-btn @click="resetForm" outlined>Cancelar</v-btn>
              <v-btn @click="patchAccount" :loading="$store.state.accounts.createLoader" color="primary">Guardar cambios</v-btn>
            </v-card-actions>
          </template>
        </v-card>
        <!-- branding -->
          <!-- <branding class="mt-5" /> -->
        <!-- end branding -->
      </template>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { required, minLength, maxLength, email, url } from 'vuelidate/lib/validators'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import Branding from '@/modules/account/views/Branding'
import cloneDeep from 'lodash/cloneDeep'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

export default {
  mixins: [
    GenericViewMixin
  ],
  components: {
    Branding, PermissionDenied
  },
  data: () => ({
    editable: {},
    backupEditable: {},
    env: process.env?.VUE_APP_MODE ?? 'ax'
  }),
  computed: mapState({
    statesList: state => state.base.statesList,
    countriesList: state => state.base.countriesList,
    accountsDetail: state => state.accounts.accountsDetail,
    activitiesList: state => state.base.activitiesList
  }),
  created () {
    if (this.$helpers.hasPermission(['view_account'])) {
      this.$store.dispatch('accounts/RETRIEVE', {
        resource: 'accounts',
        id: this.$store.state.auth.account.id
      })
      .then(() => {
        const account = cloneDeep(this.accountsDetail)
        this.editable = account
        this.backupEditable = this.accountsDetail
      })
    }
  },
  methods: {
    patchAccount () {
      this.$v.editable.$touch()
      if (this.$v.editable.$invalid) {
        return false
      }
      const payload = {
        name: this.editable.name,
        state: this.editable.state.id || this.editable.state,
        address: this.editable.address,
        email: this.editable.email,
        phone: this.editable.phone,
        website: this.editable.website
      }
      this.$store.dispatch('accounts/UPDATE', {
        resource: 'accounts',
        payload: payload,
        id: this.editable.id
      })
      .then(() => {
        this.$dialog.message.info('La información de la organización se ha actualizado')
      })
      .catch((error) => {
        this.$dialog.message.warning(this.parseErrors(error.response.data))
      })
    },
    resetForm () {
      this.editable = cloneDeep(this.backupEditable)
    }
  },
  validations: {
    editable: {
      name: {
        required,
        maxLength: maxLength(64)
      },
      state: {
        required
      },
      address: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(128)
      },
      email: {
        required,
        email,
        maxLength: maxLength(254)
      },
      phone: {
        maxLength: maxLength(16)
      },
      website: {
        url
      }
    }
  }
}
</script>